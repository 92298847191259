<template>
  <div
    class="box"
    v-loading="loading">
    <!-- <ScaleBox
      :width="5760"
      :height="1080"
      bgc="transparent"
      :delay="100"> -->
    <Dialog
      ref="dialog"
      :width="dialogParam.width"
      :title="dialogParam.title"
      :type="dialogType"
      :config="config"
      :dialogVisible="dialogVisible"
      @dialogClose="dialogVisible = false"
      :info="dialogParam.info"></Dialog>
    <div
      class="container"
      :style="{ width: boxWidth, height: boxHeight, minWidth: '570rem' }">
      <div class="header">
        <div class="time">
          {{ dateYear }}&nbsp;&nbsp;{{ dateWeek }} &nbsp;
          <el-divider direction="vertical"></el-divider>
          <span class="now-time">&nbsp;&nbsp;{{ dateDay }}</span>
        </div>
        <div class="title">
          <img src="../../assets/statisitics/Mask group@2x.png" />
          {{ schoolName }}教育系统健康监测可视化平台
        </div>
        <div class="weather">
          <div
            @click="$router.push('/statistics/screen')"
            class="pointer">
            返回首页
          </div>
          &nbsp;&nbsp;
          <img src="../../assets/statisitics/Group 1082@2x.png" />
          &nbsp;&nbsp;{{ weatcherData.tem }}30℃&nbsp;&nbsp;
          <el-divider
            direction="vertical"
            style="height: 1rem"></el-divider>
          &nbsp;&nbsp;
          <img src="../../assets/statisitics/Group 43@2x.png" />
          &nbsp;&nbsp;欢迎使用
        </div>
      </div>
      <div class="content">
        <div
          class="col-box"
          style="height: 50%">
          <div class="item-box">
            <Title
              :title="nowDate + '应检班级'"
              type="1"></Title>
            <div class="chart-layout">
              <div class="datas">
                <div>
                  <span style="color: #09246b">晨检</span>
                  &nbsp;
                  <span style="color: #1c4ac2">
                    {{ djbj[0].nums }}
                  </span>
                </div>
                <div>
                  <span style="color: #09246b">午检</span>
                  &nbsp;
                  <span style="color: #1c4ac2">
                    {{ djbj[1].nums }}
                  </span>
                </div>
                <div>
                  <span style="color: #09246b">晚检</span>
                  &nbsp;
                  <span style="color: #1c4ac2">
                    {{ djbj[2].nums }}
                  </span>
                </div>
              </div>
              <div class="chart">
                <div
                  ref="jbqk"
                  class="echarts"></div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-box"
          style="height: 50%">
          <div class="item-box">
            <Title
              :title="nowDate + '已检班级'"
              type="2"></Title>
            <div class="chart-layout">
              <div class="datas">
                <div>
                  <span style="color: #09246b">晨检</span>
                  &nbsp;
                  <span style="color: #1c4ac2">
                    {{ yjbj[0].nums }}
                  </span>
                </div>
                <div>
                  <span style="color: #09246b">午检</span>
                  &nbsp;
                  <span style="color: #1c4ac2">
                    {{ yjbj[1].nums }}
                  </span>
                </div>
                <div>
                  <span style="color: #09246b">晚检</span>
                  &nbsp;
                  <span style="color: #1c4ac2">
                    {{ yjbj[2].nums }}
                  </span>
                </div>
              </div>
              <div class="chart">
                <div
                  ref="rcwj"
                  class="echarts"></div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-box"
          style="height: 50%">
          <div class="item-box">
            <Title
              :title="nowDate + '请假'"
              type="3"></Title>
            <div class="chart-layout">
              <div class="datas">
                <div style="width: 48%">
                  <span style="color: #09246b">病假人数</span>
                  &nbsp;
                  <span style="color: #fe6463">
                    {{ rqj.sick_leave_num }}
                  </span>
                </div>
                <div style="width: 48%">
                  <span style="color: #09246b">事假人数</span>
                  &nbsp;
                  <span style="color: #1c4ac2">
                    {{ rqj.personal_leave_nu }}
                  </span>
                </div>
              </div>
              <div class="chart">
                <div
                  ref="rqj"
                  class="echarts"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="l-b">
          <div class="item-box">
            <Title
              title="晨午检完成趋势"
              type="7"></Title>
            <div
              ref="cwjwcqs"
              class="echarts"></div>
          </div>
          <div class="item-box">
            <Title
              title="出勤情况趋势"
              type="8"></Title>
            <div
              ref="cqqkqs"
              class="echarts"></div>
          </div>
        </div>
        <div class="col-box wcj">
          <div class="item-box">
            <div class="center">
              <div class="top">
                <div class="totals">
                  <div class="total">
                    <div
                      class="icon"
                      style="
                        width: 40px;
                        height: 8px;
                        background: #10b27b;
                        border-radius: 4px;
                      "></div>
                    <div class="title">在校学生数</div>
                    <div class="num">
                      {{
                        xxsj.filter((item) => item.field == '当日在校学生数')[0]
                          .number
                      }}
                    </div>
                  </div>
                  <img src="../../assets/statisitics/Group 1546@2x.png" />
                </div>
                <div class="totals">
                  <div class="total">
                    <div
                      class="icon"
                      style="
                        width: 40px;
                        height: 8px;
                        background: #5696ff;
                        border-radius: 4px;
                      "></div>
                    <div class="title">教师数量</div>
                    <div class="num">
                      {{
                        xxsj.filter((item) => item.field == '当日教师数量')[0]
                          .number
                      }}
                    </div>
                  </div>
                  <img src="../../assets/statisitics/Group 1547@2x.png" />
                </div>
                <div class="totals">
                  <div class="total">
                    <div
                      class="icon"
                      style="
                        width: 40px;
                        height: 8px;
                        background: #fe6463;
                        border-radius: 4px;
                      "></div>
                    <div class="title">晨午检人数</div>
                    <div class="num">
                      {{
                        xxsj.filter((item) => item.field == '晨午检人数')[0]
                          .number
                      }}
                    </div>
                  </div>
                  <img src="../../assets/statisitics/Group 1548@2x.png" />
                </div>
                <div class="totals">
                  <div class="total">
                    <div
                      class="icon"
                      style="
                        width: 40px;
                        height: 8px;
                        background: #4e72ef;
                        border-radius: 4px;
                      "></div>
                    <div class="title">请假人数</div>
                    <div class="num">
                      {{
                        xxsj.filter((item) => item.field == '请假人数')[0]
                          .number
                      }}
                    </div>
                  </div>
                  <img src="../../assets/statisitics/Group 1549@2x.png" />
                </div>
              </div>
              <div class="bottom">
                <Title
                  :title="schoolName + '当日晨午检情况'"
                  type="9"></Title>
                <div class="table">
                  <div class="operation">
                    <div>
                      <div class="title">年级</div>
                      <el-select
                        v-model="query.gradeCode"
                        placeholder="请选择年级"
                        clearable
                        @change="getClass(), getIllNums()">
                        <el-option
                          v-for="item in nj"
                          :key="item.GRADE_CODE"
                          :value="item.GRADE_CODE"
                          :label="item.GRADE_NAME"></el-option>
                      </el-select>
                      &nbsp;&nbsp;
                      <div class="title">班级</div>
                      <el-select
                        clearable
                        v-model="query.classCode"
                        placeholder="请选择班级"
                        @change="getIllNums()">
                        <el-option
                          v-for="item in bj"
                          :key="item.CLASS_CODE"
                          :value="item.CLASS_CODE"
                          :label="item.CLASS_NAME"></el-option>
                      </el-select>
                      &nbsp;&nbsp;
                      <div
                        class="title"
                        style="width: 12rem">
                        学生姓名
                      </div>
                      <el-input
                        placeholder="请输入查询"
                        v-model="query.name"
                        @change="getIllNums()"
                        style="width: 32rem"></el-input>
                    </div>
                  </div>
                  <el-table
                    :data="cwjqk"
                    height="440"
                    :header-cell-style="{
                      background: '#ebf2ff',
                      fontSize: '2.8rem',
                      fontWeight: '100',
                      height: '8rem',
                    }"
                    @row-click="getStudentInfo">
                    <el-table-column
                      prop="年级"
                      label="年级"></el-table-column>
                    <el-table-column
                      prop="班级"
                      label="班级"></el-table-column>
                    <el-table-column
                      prop="学生"
                      label="学生姓名"></el-table-column>
                    <el-table-column
                      prop="检查类型"
                      label="检查类型"></el-table-column>
                    <el-table-column
                      prop="请假类型"
                      label="请假类型"></el-table-column>
                    <el-table-column
                      prop="缺勤原因"
                      label="缺勤类型"></el-table-column>
                    <el-table-column
                      prop="疾病名称"
                      label="病症类型"></el-table-column>
                    <el-table-column
                      prop="是否传染性疾病"
                      label="是否传染病"></el-table-column>
                  </el-table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-box">
          <div class="item-box">
            <Title
              :title="nowDate + '传染病分布'"
              type="4"></Title>
            <div
              ref="crbfb"
              class="echarts"></div>
          </div>
          <div class="item-box">
            <Title
              title="近一周传染病趋势"
              type="10"></Title>
            <div
              ref="jyzcrbqs"
              class="echarts"></div>
          </div>
        </div>
        <div class="col-box">
          <div class="item-box">
            <Title
              :title="nowDate + '常规病排名'"
              type="5"></Title>
            <div
              ref="jyzptb"
              class="echarts"></div>
          </div>
          <div class="item-box">
            <Title
              title="近一周病情趋势"
              type="11"></Title>
            <div
              ref="jyzbqqs"
              class="echarts"></div>
          </div>
        </div>
        <div class="col-box">
          <div class="item-box">
            <Title
              :title="nowDate + '症状情况'"
              type="6"></Title>
            <div
              ref="rzzpm"
              class="echarts"></div>
          </div>
          <div class="item-box">
            <Title
              title="近一周病症趋势"
              type="12"></Title>
            <div
              ref="jyzbzqs"
              class="echarts"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- </ScaleBox> -->
  </div>
</template>
<script>
import datas from './schoolDatas.json';
import datas2 from './schoolDatas2.json';
import datas3 from './schoolDatas3.json';
import datas4 from './schoolCrb.json';
import datas5 from './ptb.json';
import datas6 from './bz.json';
import datas7 from './cwjqs.json';
import datas8 from './cqqs.json';
import datas9 from './student.json';
import datas12 from './jyzbqqs.json';
import datas11 from './crbqs.json';
import datas10 from './bzqs.json';
import { fontChart } from '@/utils/echartPxToRem';
import ScaleBox from 'vue2-scale-box';
import * as echarts from 'echarts';
import Title from './components/Title2.vue';
import Dialog from './components/Dialog.vue';
import axios from 'axios';
import dayjs from 'dayjs';
import {
  getToken,
  schoolShouldCheckClassData,
  schoolCheckedClassData,
  schoolLeaveData,
  schoolSynthesizeData,
  schoolDiseaseData,
  schoolConventionalDiseaseData,
  schoolSymptomDataData,
  schoolStuLeaveCheckTrendData,
  schoolAttendanceTrendsData,
  schoolStuLeaveInfoData,
  schoolDiseaseTrendsData,
  schoolGradeTabulationData,
  schoolClassTabulationData,
  schoolSymptomTrendsData,
  schoolIllnessTrendsData,
} from '@/api/statisitics/yzt';
// import {
//   getBaseInfo,
//   getCheckNums,
//   getLeaveNums,
//   getLnfect,
//   getNormal,
//   getSymptom,
//   getNormalT,
//   getLnfectT,
//   getFinishTrend,
//   getHaveTo,
//   getIll,
//   getSym,
//   getTrends,
// } from '@/api/statisitics/index';
export default {
  data() {
    return {
      loading: false,
      dialogType: '',
      dialogVisible: false,
      dialogParam: {
        title: '',
        width: '40%',
        info: '',
      },
      schoolName: '',
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: [
        '星期日',
        '星期一',
        '星期二',
        '星期三',
        '星期四',
        '星期五',
        '星期六',
      ],
      timer: null,
      token: '',
      timepiece: 0, //计时器
      refreshTime: 2, //刷新时间（秒）
      chenWuState: 1, //1晨2午3晚
      weatcherData: {}, //天气信息
      djbj: [
        { type: 'chen', nums: 0 },
        { type: 'wu', nums: 0 },
        { type: 'wan', nums: 0 },
      ], //应检班级
      yjbj: [
        { type: 'chen', nums: 0 },
        { type: 'wu', nums: 0 },
        { type: 'wan', nums: 0 },
      ], //已检班级
      rqj: [], //日请假
      rcrb: [], //传染病分布
      rcgb: [], //日常规病
      rzz: [], //日症状
      cwjwcqs: [], //晨午检完成趋势
      cqqkqs: [], //出勤情况趋势
      cwjqk: [], //晨午检情况
      jyzbqqs: [], //近一周病情趋势
      jyzcrbqs: [], //近一周传染病趋势
      jyzbzqs: [], //近一周病症趋势

      nj: [], //年级
      bj: [], //班级
      query: {}, //查询条件
      datas, //详细信息
      datas2, //详细信息
      datas3, //详细信息
      datas4,
      datas5,
      datas6,
      datas7,
      datas8,
      datas9,
      datas10,
      datas11,
      datas12,
      dynamicMonitoring: [], //晨午检实时动态
      checkNums: {}, //3月24日晨午检
      leaveNums: [], //3月24日请假
      // crbfb: {
      //   indicator: [],
      //   datas: [0, 0, 0, 0, 0, 0, 0],
      // }, //传染病分布
      ptb: [], //普通并
      zzpm: [
        {
          name: '咳嗽',
          value: 0,
        },
        {
          name: '发烧',
          value: 0,
        },
        {
          name: '水痘',
          value: 0,
        },
        {
          name: '上呼吸道感染',
          value: 0,
        },
        {
          name: '头痛',
          value: 0,
        },
        {
          name: '四肢无力',
          value: 0,
        },
        {
          name: '咽痛',
          value: 0,
        },
      ], //症状排名
      bqqs: [], //病情趋势
      crbqs: [], //传染病趋势
      bzqs: [], //病症趋势
      xxsj: [
        { field: '当日在校学生数', number: 0 },
        { number: 0, field: '当日教师数量' },
        { number: 0, field: '晨午检人数' },
        { number: 0, field: '请假人数' },
      ], //学校综合数据
      wcqs: {
        chen: [],
        wu: [],
        wan: [],
      },
      temp: '',
      cqqs: {
        yingdao: [],
        shidao: [],
      },
      student: [],
      haveTo: [],
      finishTrend: [],
      lnfectT: [],
      normalT: [],
      symptom: [],
      normal: [],
      lnfect: [],
      cwj: [],

      boxWidth: '',
      boxHeight: '',
      config: {
        appId: 'S20232002',
        appSecret: '300cc0a242577bb0d78bcabf339ca08d',
        timestamp: new Date().getTime(),
        header: '',
        schoolCode: this.$route.query.id,
      },
      nowDate: dayjs(this.getFirstDayAndLastDay().endDate).format('M月D日'),
    };
  },
  components: {
    Title,
    Dialog,
    // ScaleBox,
  },
  created() {
    this.boxWidth = window.innerWidth + 'px';
    this.boxHeight = window.innerHeight + 'px';
  },
  mounted() {
    window.addEventListener('keydown', this.KeyDown, true);
    this.timer = setInterval(() => {
      const date = dayjs(new Date());
      this.dateDay = date.format('HH:mm:ss');
      this.dateYear = date.format('YYYY-MM-DD');
      this.dateWeek = date.format(this.weekday[date.day()]);
      this.timepiece += 1;
      //大于1.5小时刷新token
      if (this.timepiece > 3600) {
        this.getToken();
        this.timepiece = 0;
      }
    }, 1000);
    //this.getTimeState();
    this.init();
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    KeyDown(event) {
      if (event.keyCode === 122) {
        location.reload();
      }
    },
    // 计算起始日期的中间日期
    getAllDate(start, end, type) {
      let format = (time) => {
        let ymd = '';
        let mouth = time.getMonth() + 1;
        let day = time.getDate() >= 10 ? time.getDate() : '0' + time.getDate();
        let hour =
          time.getHours() >= 10 ? time.getHours() : '0' + time.getHours();
        ymd += time.getFullYear() + '-'; // 获取年份。
        ymd += mouth + '-'; // 获取月份。
        ymd += day; // 获取日。
        if (type === 1) {
          ymd += ' ' + hour + ':00'; // 获取小时。
        }
        //m.df格式
        return ymd; // 返回日期。
      };
      let dateArr = [];
      let startArr = start.split('-');
      let endArr = end.split('-');
      let db = new Date();
      db.setUTCFullYear(startArr[0], startArr[1] - 1, startArr[2]);
      if (type === 1) {
        db.setHours(startArr[3]);
      } else {
        db.setHours(0, 0, 0, 0);
      }
      let de = new Date();
      de.setUTCFullYear(endArr[0], endArr[1] - 1, endArr[2]);
      if (type === 1) {
        de.setHours(Number(endArr[3]) + 1);
      } else {
        de.setHours(0, 0, 0, 0);
      }
      let unixDb = db.getTime();
      let unixDe = de.getTime();
      let stamp;
      let oneDay = null;
      if (type === 1) {
        oneDay = 60 * 60 * 1000;
      } else if (type === 2) {
        oneDay = 24 * 60 * 60 * 1000;
      } else if (type === 3) {
        oneDay = 24 * 60 * 60 * 1000 * 7;
      }
      for (stamp = unixDb; stamp <= unixDe; ) {
        if (
          new Date(format(new Date(parseInt(stamp)))).getDay() < 6 &&
          new Date(format(new Date(parseInt(stamp)))).getDay() != 0
        ) {
          dateArr.push(dayjs(new Date(parseInt(stamp))).format('YYYY-MM-DD'));
        }

        stamp = stamp + oneDay;
      }
      return dateArr;
    },
    getDay(day) {
      var today = new Date();

      var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;

      today.setTime(targetday_milliseconds); //注意，这行是关键代码

      var tYear = today.getFullYear();

      var tMonth = today.getMonth();

      var tDate = today.getDate();

      tMonth = this.doHandleMonth(tMonth + 1);

      tDate = this.doHandleMonth(tDate);

      return tYear + '-' + tMonth + '-' + tDate;
    },

    doHandleMonth(month) {
      var m = month;

      if (month.toString().length == 1) {
        m = '0' + month;
      }

      return m;
    },
    //判断本周第一天和最后一天
    getFirstDayAndLastDay() {
      return {
        startDate: this.getDay(-6),
        endDate: this.getDay(0),
        allDate: this.getAllDate(this.getDay(-6), this.getDay(0), 2),
        // startDate: dayjs(monday).format('YYYY-MM-DD'),
        // endDate: dayjs(sunday).format('YYYY-MM-DD'),
      };
      // let date = new Date();
      // var weekday = date.getDay() || 7;
      // let monday = date.setDate(date.getDate() - weekday + 1);
      // let sunday = '';
      // if (weekday < 6) {
      //   sunday = new Date();
      // } else {
      //   sunday = new Date(
      //     date.getTime() +
      //       3600 * 1000 * 24 * (date.getDay() == 0 ? 0 : 7 - date.getDay() - 2)
      //   );
      // }
      // return {
      //   startDate: dayjs(monday).format('YYYY-MM-DD'),
      //   endDate: dayjs(sunday).format('YYYY-MM-DD'),
      // };
    },
    fullScreenChangeFn(e) {
      if (window.screen.height - window.document.body.offsetHeight > 5) {
        return false;
      } else {
        return true;
      }
    },
    dataResort(arr, check_date, check_type_name, checked_num) {
      let newArr = [];
      arr.forEach((item) => {
        let index = -1;
        let isExists = newArr.some((newItem, j) => {
          if (item[check_date] == newItem.check_date) {
            index = j;
            return true;
          }
        });
        if (!isExists) {
          newArr.push({
            check_date: item[check_date],
            subList: [item[check_type_name]],
            nums: [item[checked_num]],
          });
        } else {
          newArr[index].subList.push(item[check_type_name]);
          newArr[index].nums.push(item[checked_num]);
        }
      });
      return newArr;
    },
    async init() {
      //this.loading = true;
      //获取token
      const { data: token } = await getToken({
        appId: 'S20232002',
        appSecret: '300cc0a242577bb0d78bcabf339ca08d',
        timestamp: new Date().getTime(),
      });
      this.config.header = token;
      //获取学校应检班级
      const { data: djbj } = await schoolShouldCheckClassData({
        ...this.config,
        startDate: this.getFirstDayAndLastDay().endDate,
        endDate: this.getFirstDayAndLastDay().endDate,
      });
      if (eval(djbj).length > 0) {
        this.schoolName = eval(djbj)[0]['学校'];
      } else {
        this.schoolName = this.$route.query.name;
      }

      eval(djbj).map((item) => {
        if (item['检查类型'] == '晨检') {
          this.djbj[0].nums = item['应检人数'];
        }
        if (item['检查类型'] == '午检') {
          this.djbj[1].nums = item['应检人数'];
        }
        if (item['检查类型'] == '晚检') {
          this.djbj[2].nums = item['应检人数'];
        }
      });
      //应检班级
      this.initJbqk();
      //日症状
      const { data: rzz } = await schoolSymptomDataData({
        ...this.config,
        startDate: this.getFirstDayAndLastDay().endDate,
        endDate: this.getFirstDayAndLastDay().endDate,
      });
      let rzzDatas = [
        { illnessName: '咳嗽', illnessNumber: 0 },
        { illnessName: '发烧', illnessNumber: 0 },
        { illnessName: '腹泻', illnessNumber: 0 },
        { illnessName: '呕吐', illnessNumber: 0 },
        { illnessName: '流鼻涕', illnessNumber: 0 },
        { illnessName: '头晕', illnessNumber: 0 },
        { illnessName: '上呼吸道感染', illnessNumber: 0 },
      ];
      rzzDatas.map((item1) => {
        eval(rzz).map((item2) => {
          if (item1.illnessName == item2.illnessName) {
            item1.illnessNumber = item2.number;
          }
        });
        this.rzz.push(item1);
      });
      //日症状排名
      this.initRzzpm();
      //获取对应学校已检班级
      const { data: yjbj } = await schoolCheckedClassData({
        ...this.config,
        startDate: this.getFirstDayAndLastDay().endDate,
        endDate: this.getFirstDayAndLastDay().endDate,
      });
      eval(yjbj).map((item) => {
        if (item['检查类型'] == '晨检') {
          this.yjbj[0].nums = item['已检人数'];
        }
        if (item['检查类型'] == '午检') {
          this.yjbj[1].nums = item['已检人数'];
        }
        if (item['检查类型'] == '晚检') {
          this.yjbj[2].nums = item['已检人数'];
        }
      });
      //已检班级
      this.initRcwj();
      //日请假
      const { data: rqj } = await schoolLeaveData({
        ...this.config,
        startDate: this.getFirstDayAndLastDay().endDate,
        endDate: this.getFirstDayAndLastDay().endDate,
      });
      if (eval(rqj)[0] != null) {
        this.rqj = eval(rqj)[0];
      } else {
        this.rqj = {
          sick_leave_num: 0,
          personal_leave_nu: 0,
        };
      }

      //日请假
      this.initRqj();
      //学校综合数据
      const { data: xxsj } = await schoolSynthesizeData({
        ...this.config,
        startDate: this.getFirstDayAndLastDay().endDate,
        endDate: this.getFirstDayAndLastDay().endDate,
      });
      let newXxsj = [
        {
          field: '当日在校学生数',
          number: 0,
        },
        {
          field: '当日教师数量',
          number: 0,
        },
        {
          field: '晨午检人数',
          number: 0,
        },
        {
          field: '请假人数',
          number: 0,
        },
      ];
      newXxsj.map((item1) => {
        eval(xxsj).map((item2) => {
          if (item1.field == item2.field) {
            item1.number = item2.number ? item2.number : 0;
          }
        });
      });
      console.log(newXxsj);
      this.xxsj = newXxsj;

      //日传染病分布
      const { data: rcrb } = await schoolDiseaseData({
        ...this.config,
        startDate: this.getFirstDayAndLastDay().endDate,
        endDate: this.getFirstDayAndLastDay().endDate,
      });
      let crbfbDatas = [
        { illnessName: '甲流', illnessNumber: 0 },
        { illnessName: '新冠病毒感染', illnessNumber: 0 },
        { illnessName: '流行性感冒', illnessNumber: 0 },
        { illnessName: '流行性腮腺炎', illnessNumber: 0 },
        { illnessName: '诺如病毒感染', illnessNumber: 0 },
        { illnessName: '疱疹性咽峡炎', illnessNumber: 0 },
        { illnessName: '水痘', illnessNumber: 0 },
      ];
      crbfbDatas.map((item1) => {
        eval(rcrb).map((item2) => {
          if (item1.illnessName == item2.ILLNESS_NAME) {
            item1.illnessNumber = item2.number;
          }
        });
        this.rcrb.push(item1);
      });
      //传染病分布
      this.initCrbfb();
      //日常规病排名
      let rcgbDatas = [
        { illnessName: '普通感冒', illnessNumber: 0 },
        { illnessName: '其他普通疾病', illnessNumber: 0 },
        { illnessName: '胃肠炎', illnessNumber: 0 },
        { illnessName: '过敏性皮炎', illnessNumber: 0 },
        { illnessName: '湿疹', illnessNumber: 0 },
        { illnessName: '支气管炎', illnessNumber: 0 },
        { illnessName: '意外伤害', illnessNumber: 0 },
        // { illnessName: '病因不明', illnessNumber: 0 },
        { illnessName: '耳鼻喉疾病', illnessNumber: 0 },
      ];
      const { data: rcgb } = await schoolConventionalDiseaseData({
        ...this.config,
        startDate: this.getFirstDayAndLastDay().endDate,
        endDate: this.getFirstDayAndLastDay().endDate,
      });
      rcgbDatas.map((item1) => {
        eval(rcgb).map((item2) => {
          if (item1.illnessName == item2.ILLNESS_NAME) {
            item1.illnessNumber = item2.number;
          }
        });
        this.rcgb.push(item1);
      });
      //日常规病
      this.initJyzptb();

      //晨午检完成趋势
      const { data: wcqs } = await schoolStuLeaveCheckTrendData({
        ...this.config,
        ...this.getFirstDayAndLastDay(),
      });
      this.cwjwcqs = this.dataResort(
        eval(wcqs),
        '检查时间',
        '检查类型',
        '已检人数'
      );
      //晨午检完成趋势
      this.initCwjwcqsChart();
      //出勤情况趋势
      const { data: cqqkqs } = await schoolAttendanceTrendsData({
        ...this.config,
        ...this.getFirstDayAndLastDay(),
      });
      this.cqqkqs = this.dataResort(eval(cqqkqs), '日期', '实到', '应到');
      //出勤情况趋势
      this.initCqqkqs();
      //晨午检情况
      this.getIllNums();

      //获取年级
      const { data: nj } = await schoolGradeTabulationData({
        ...this.config,
      });
      this.nj = eval(nj);

      //近一周传染病趋势
      const { data: jyzcrbqs } = await schoolDiseaseTrendsData({
        ...this.config,
        ...this.getFirstDayAndLastDay(),
      });
      let crb = [
        { name: '甲流' },
        { name: '新冠病毒感染' },
        { name: '水痘' },
        { name: '流行性感冒' },
        { name: '流行性腮腺炎' },
        // { name: '诺如病毒感染' },
        // { name: '痕疹性咽峡炎' },
      ];
      let crbqsDatas = this.dataResort(
        eval(jyzcrbqs),
        'date',
        'number',
        'illnessName'
      );
      let newCrbArray = [];
      crbqsDatas.map((item1) => {
        crb.map((item2) => {
          newCrbArray.push({
            date: item1.check_date,
            illnessName: item2.name,
            illnessNumber:
              item1.nums.indexOf(item2.name) > -1
                ? item1.subList[item1.nums.indexOf(item2.name)]
                : 0,
          });
        });
      });
      let jyzcrbqsArr = this.dataResort(
        eval(newCrbArray),
        'date',
        'illnessNumber',
        'illnessName'
      );
      //数据处理(去除周末，补全缺少日期)
      if (jyzcrbqsArr.length > 0) {
        this.getFirstDayAndLastDay().allDate.map((date) => {
          let value = jyzcrbqsArr.filter((item) => item.check_date == date);
          if (value.length > 0) {
            this.jyzcrbqs.push(value[0]);
          } else {
            this.jyzcrbqs.push({
              check_date: date,
              nums: crb.map((item) => {
                return item.name;
              }),
              subList: crb.map((item) => {
                return 0;
              }),
            });
          }
        });
      } else {
        this.getFirstDayAndLastDay().allDate.map((date) => {
          this.jyzcrbqs.push({
            check_date: date,
            nums: crb.map((item) => {
              return item.name;
            }),
            subList: crb.map((item) => {
              return 0;
            }),
          });
        });
      }
      //近一周传染病趋势
      this.initJyzcrbqs();
      //近一周病情趋势
      let bqqs = [
        {
          name: '普通感冒',
        },
        {
          name: '胃肠炎',
        },
        {
          name: '支气管炎',
        },
        {
          name: '耳鼻喉疾病',
        },
        {
          name: '过敏性皮炎',
        },
        {
          name: '意外伤害',
        },
      ];
      const { data: jyzbqqs } = await schoolIllnessTrendsData({
        ...this.config,
        ...this.getFirstDayAndLastDay(),
      });
      let bqqsData = this.dataResort(
        eval(jyzbqqs),
        'date',
        'number',
        'illnessName'
      );
      let newbqqsArray = [];
      bqqsData.map((item1) => {
        bqqs.map((item2) => {
          newbqqsArray.push({
            date: item1.check_date,
            illnessName: item2.name,
            illnessNumber:
              item1.nums.indexOf(item2.name) > -1
                ? item1.subList[item1.nums.indexOf(item2.name)]
                : 0,
          });
        });
      });

      let jyzbqqsArr = this.dataResort(
        eval(newbqqsArray),
        'date',
        'illnessNumber',
        'illnessName'
      );
      //数据处理(去除周末，补全缺少日期)
      if (jyzbqqsArr.length > 0) {
        this.getFirstDayAndLastDay().allDate.map((date) => {
          let value = jyzbqqsArr.filter((item) => item.check_date == date);
          if (value.length > 0) {
            this.jyzbqqs.push(value[0]);
          } else {
            this.jyzbqqs.push({
              check_date: date,
              nums: bqqs.map((item) => {
                return item.name;
              }),
              subList: bqqs.map((item) => {
                return 0;
              }),
            });
          }
        });
      } else {
        this.getFirstDayAndLastDay().allDate.map((date) => {
          this.jyzbqqs.push({
            check_date: date,
            nums: bqqs.map((item) => {
              return item.name;
            }),
            subList: bqqs.map((item) => {
              return 0;
            }),
          });
        });
      }
      //近一周病情趋势
      this.initJyzbqqs();
      //近一周病症趋势
      let bzqs = [
        {
          name: '咳嗽',
        },
        {
          name: '发烧',
        },
        {
          name: '腹泻',
        },
        {
          name: '呕吐',
        },
        {
          name: '流鼻涕',
        },
        {
          name: '头晕',
        },
        {
          name: '咽痛',
        },
        {
          name: '四肢无力',
        },
      ];
      const { data: jyzbzqs } = await schoolSymptomTrendsData({
        ...this.config,
        ...this.getFirstDayAndLastDay(),
      });
      let bzqsData = this.dataResort(
        eval(jyzbzqs),
        'date',
        'number',
        'illnessName'
      );
      if (bzqsData.length == 0) {
        bzqsData = [
          {
            check_date: this.getFirstDayAndLastDay().endDate,
            nums: [
              '咳嗽',
              '发烧',
              '腹泻',
              '呕吐',
              '流鼻涕',
              '头晕',
              '咽痛',
              '四肢无力',
            ],
            subList: [0, 0, 0, 0, 0, 0, 0, 0],
          },
        ];
      }
      let newbzqsArray = [];
      bzqsData.map((item1) => {
        bzqs.map((item2) => {
          newbzqsArray.push({
            date: item1.check_date,
            illnessName: item2.name,
            illnessNumber:
              item1.nums.indexOf(item2.name) > -1
                ? item1.subList[item1.nums.indexOf(item2.name)]
                : 0,
          });
        });
      });
      let jyzbzqsArr = this.dataResort(
        eval(newbzqsArray),
        'date',
        'illnessNumber',
        'illnessName'
      );
      //数据处理(去除周末，补全缺少日期)
      if (jyzbzqsArr.length > 0) {
        this.getFirstDayAndLastDay().allDate.map((date) => {
          let value = jyzbzqsArr.filter((item) => item.check_date == date);
          if (value.length > 0) {
            this.jyzbzqs.push(value[0]);
          } else {
            this.jyzbzqs.push({
              check_date: date,
              nums: bzqs.map((item) => {
                return item.name;
              }),
              subList: bzqs.map((item) => {
                return 0;
              }),
            });
          }
        });
      } else {
        this.getFirstDayAndLastDay().allDate.map((date) => {
          this.jyzbzqs.push({
            check_date: date,
            nums: bzqs.map((item) => {
              return item.name;
            }),
            subList: bzqs.map((item) => {
              return 0;
            }),
          });
        });
      }
      //近一周病症趋势
      this.initJyzbzqs();
      // let res2 = this.datas3.filter((item) => item.id == this.$route.query.id);
      // this.leaveNums = res2[0];
      // let res3 = this.datas4.filter((item) => item.id == this.$route.query.id);
      // this.crbfb.indicator = [
      //   '甲流',
      //   '流行性感冒',
      //   '其他传染病',
      //   '疱疹性咽峡炎',
      //   '流行性腮腺炎',
      //   '水痘',
      //   '肺结核',
      // ];
      // res3.map((item) => {
      //   console.log(item);
      //   if (item['日期'] == '2023-03-24' && item.ILLNESS_NAME == '甲流') {
      //     this.crbfb.datas[0] = item['人数'];
      //   }
      //   if (item['日期'] == '2023-03-24' && item.ILLNESS_NAME == '流行性感冒') {
      //     this.crbfb.datas[1] = item['人数'];
      //   }
      //   if (item['日期'] == '2023-03-24' && item.ILLNESS_NAME == '其他传染病') {
      //     this.crbfb.datas[2] = item['人数'];
      //   }
      //   if (
      //     item['日期'] == '2023-03-24' &&
      //     item.ILLNESS_NAME == '疱疹性咽峡炎'
      //   ) {
      //     this.crbfb.datas[3] = item['人数'];
      //   }
      //   if (
      //     item['日期'] == '2023-03-24' &&
      //     item.ILLNESS_NAME == '流行性腮腺炎'
      //   ) {
      //     this.crbfb.datas[4] = item['人数'];
      //   }
      //   if (item['日期'] == '2023-03-24' && item.ILLNESS_NAME == '水痘') {
      //     this.crbfb.datas[5] = item['人数'];
      //   }
      //   if (item['日期'] == '2023-03-24' && item.ILLNESS_NAME == '肺结核') {
      //     this.crbfb.datas[6] = item['人数'];
      //   }
      // });
      // let res4 = this.datas5.filter((item) => item.id == this.$route.query.id);
      // res4.map((item) => {
      //   if (item.time == '2023-03-24') {
      //     this.ptb.push({
      //       name: item.pName,
      //       num: item.nums,
      //     });
      //   }
      // });
      // let res5 = this.datas6.filter((item) => item.id == this.$route.query.id);
      // res5.map((item) => {
      //   if (item.time == '2023-03-24') {
      //     for (var i in this.zzpm) {
      //       if (this.zzpm[i].name == item.bz) {
      //         this.zzpm[i].value = item.nums;
      //       }
      //     }
      //     // this.zzpm.push({
      //     //   name: item.bz,
      //     //   num: item.nums,
      //     // });
      //   }
      // });
      // let res6 = this.datas7.filter((item) => item.id == this.$route.query.id);
      // res6.map((item) => {
      //   if (item.checkTime.split('-')[2] >= 20) {
      //     if (item.checkType == '晨检') {
      //       this.wcqs.chen.push(item.nums);
      //     }
      //     if (item.checkType == '午检') {
      //       this.wcqs.wu.push(item.nums);
      //     }
      //     if (item.checkType == '晚检') {
      //       this.wcqs.wan.push(item.nums);
      //     }
      //   }
      // });
      // let res7 = this.datas8.filter(
      //   (item) => item.name == this.$route.query.name
      // );
      // res7.map((item) => {
      //   if (item.time.split('-')[2] >= 20) {
      //     this.cqqs.yingdao.push(item.yingdao);
      //     this.cqqs.shidao.push(item.shidao);
      //   }
      // });
      // let res8 = this.datas9.filter(
      //   (item) => item.name == this.$route.query.name
      // );
      // this.students = res8;
      // //病情趋势
      // let res9 = this.datas10.filter((item) => item.id == this.$route.query.id);
      // let datas = [
      //   {
      //     水痘: [0, 0, 0, 0, 0],
      //   },
      //   {
      //     湿疹: [0, 0, 0, 0, 0],
      //   },
      // ];
      // res9.map((item) => {
      //   if (item.time.split('-')[2] >= 20) {
      //     if (item.time == '2023-03-20' && item.bzm == '水痘') {
      //       datas[0]['水痘'][0] = item.num;
      //     }
      //     if (item.time == '2023-03-21' && item.bzm == '水痘') {
      //       datas[0]['水痘'][1] = item.num;
      //     }
      //     if (item.time == '2023-03-22' && item.bzm == '水痘') {
      //       datas[0]['水痘'][2] = item.num;
      //     }
      //     if (item.time == '2023-03-23' && item.bzm == '水痘') {
      //       datas[0]['水痘'][3] = item.num;
      //     }
      //     if (item.time == '2023-03-24' && item.bzm == '水痘') {
      //       datas[0]['水痘'][4] = item.num;
      //     }
      //     if (item.time == '2023-03-20' && item.bzm == '湿疹') {
      //       datas[1]['湿疹'][0] = item.num;
      //     }
      //     if (item.time == '2023-03-21' && item.bzm == '湿疹') {
      //       datas[1]['湿疹'][1] = item.num;
      //     }
      //     if (item.time == '2023-03-22' && item.bzm == '湿疹') {
      //       datas[1]['湿疹'][2] = item.num;
      //     }
      //     if (item.time == '2023-03-23' && item.bzm == '湿疹') {
      //       datas[1]['湿疹'][3] = item.num;
      //     }
      //     if (item.time == '2023-03-24' && item.bzm == '湿疹') {
      //       datas[1]['湿疹'][4] = item.num;
      //     }
      //   }
      // });
      // this.bqqs = datas;
      // let datas2 = [
      //   {
      //     甲流: [0, 0, 0, 0, 0],
      //   },
      //   {
      //     流行性感冒: [0, 0, 0, 0, 0],
      //   },
      //   {
      //     流行性腮腺炎: [0, 0, 0, 0, 0],
      //   },
      // ];
      // let res10 = this.datas11.filter(
      //   (item) => item.id == this.$route.query.id
      // );
      // res10.map((item) => {
      //   if (item.time.split('-')[2] >= 20) {
      //     if (item.time == '2023-03-20' && item.crb == '甲流') {
      //       datas2[0]['甲流'][0] = item.num;
      //     }
      //     if (item.time == '2023-03-21' && item.crb == '甲流') {
      //       datas2[0]['甲流'][1] = item.num;
      //     }
      //     if (item.time == '2023-03-22' && item.crb == '甲流') {
      //       datas2[0]['甲流'][2] = item.num;
      //     }
      //     if (item.time == '2023-03-23' && item.crb == '甲流') {
      //       datas2[0]['甲流'][3] = item.num;
      //     }
      //     if (item.time == '2023-03-24' && item.crb == '甲流') {
      //       datas2[0]['甲流'][4] = item.num;
      //     }
      //     if (item.time == '2023-03-20' && item.crb == '流行性感冒') {
      //       datas2[1]['流行性感冒'][0] = item.num;
      //     }
      //     if (item.time == '2023-03-21' && item.crb == '流行性感冒') {
      //       datas2[1]['流行性感冒'][1] = item.num;
      //     }
      //     if (item.time == '2023-03-22' && item.crb == '流行性感冒') {
      //       datas2[1]['流行性感冒'][2] = item.num;
      //     }
      //     if (item.time == '2023-03-23' && item.crb == '流行性感冒') {
      //       datas2[1]['流行性感冒'][3] = item.num;
      //     }
      //     if (item.time == '2023-03-24' && item.crb == '流行性感冒') {
      //       datas2[1]['流行性感冒'][4] = item.num;
      //     }
      //     if (item.time == '2023-03-20' && item.crb == '流行性腮腺炎') {
      //       datas2[2]['流行性腮腺炎'][0] = item.num;
      //     }
      //     if (item.time == '2023-03-21' && item.crb == '流行性腮腺炎') {
      //       datas2[2]['流行性腮腺炎'][1] = item.num;
      //     }
      //     if (item.time == '2023-03-22' && item.crb == '流行性腮腺炎') {
      //       datas2[2]['流行性腮腺炎'][2] = item.num;
      //     }
      //     if (item.time == '2023-03-23' && item.crb == '流行性腮腺炎') {
      //       datas2[2]['流行性腮腺炎'][3] = item.num;
      //     }
      //     if (item.time == '2023-03-24' && item.crb == '流行性腮腺炎') {
      //       datas2[2]['流行性腮腺炎'][4] = item.num;
      //     }
      //   }
      // });
      // this.crbqs = datas2;
      // let datas3 = [
      //   {
      //     头晕: [0, 0, 0, 0, 0],
      //   },
      //   {
      //     咳嗽: [0, 0, 0, 0, 0],
      //   },
      //   {
      //     咽痛: [0, 0, 0, 0, 0],
      //   },
      //   {
      //     腹泻: [0, 0, 0, 0, 0],
      //   },
      //   {
      //     发烧: [0, 0, 0, 0, 0],
      //   },
      // ];
      // let res11 = this.datas12.filter(
      //   (item) => item.id == this.$route.query.id
      // );
      // console.log(this.datas12);
      // res11.map((item) => {
      //   if (item.time.split('-')[2] >= 20) {
      //     if (item.time == '2023-03-20' && item.crb == '头晕') {
      //       datas3[0]['头晕'][0] = item.num;
      //     }
      //     if (item.time == '2023-03-21' && item.crb == '头晕') {
      //       datas3[0]['头晕'][1] = item.num;
      //     }
      //     if (item.time == '2023-03-22' && item.crb == '头晕') {
      //       datas3[0]['头晕'][2] = item.num;
      //     }
      //     if (item.time == '2023-03-23' && item.crb == '头晕') {
      //       datas3[0]['头晕'][3] = item.num;
      //     }
      //     if (item.time == '2023-03-24' && item.crb == '头晕') {
      //       datas3[0]['头晕'][4] = item.num;
      //     }
      //     if (item.time == '2023-03-20' && item.crb == '咳嗽') {
      //       datas3[1]['咳嗽'][0] = item.num;
      //     }
      //     if (item.time == '2023-03-21' && item.crb == '咳嗽') {
      //       datas3[1]['咳嗽'][1] = item.num;
      //     }
      //     if (item.time == '2023-03-22' && item.crb == '咳嗽') {
      //       datas3[1]['咳嗽'][2] = item.num;
      //     }
      //     if (item.time == '2023-03-23' && item.crb == '咳嗽') {
      //       datas3[1]['咳嗽'][3] = item.num;
      //     }
      //     if (item.time == '2023-03-24' && item.crb == '咳嗽') {
      //       datas3[1]['咳嗽'][4] = item.num;
      //     }
      //     if (item.time == '2023-03-20' && item.crb == '咽痛') {
      //       datas3[2]['咽痛'][0] = item.num;
      //     }
      //     if (item.time == '2023-03-21' && item.crb == '咽痛') {
      //       datas3[2]['咽痛'][1] = item.num;
      //     }
      //     if (item.time == '2023-03-22' && item.crb == '咽痛') {
      //       datas3[2]['咽痛'][2] = item.num;
      //     }
      //     if (item.time == '2023-03-23' && item.crb == '咽痛') {
      //       datas3[2]['咽痛'][3] = item.num;
      //     }
      //     if (item.time == '2023-03-24' && item.crb == '咽痛') {
      //       datas3[2]['咽痛'][4] = item.num;
      //     }
      //     if (item.time == '2023-03-20' && item.crb == '腹泻') {
      //       datas3[3]['腹泻'][0] = item.num;
      //     }
      //     if (item.time == '2023-03-21' && item.crb == '腹泻') {
      //       datas3[3]['腹泻'][1] = item.num;
      //     }
      //     if (item.time == '2023-03-22' && item.crb == '腹泻') {
      //       datas3[3]['腹泻'][2] = item.num;
      //     }
      //     if (item.time == '2023-03-23' && item.crb == '腹泻') {
      //       datas3[3]['腹泻'][3] = item.num;
      //     }
      //     if (item.time == '2023-03-24' && item.crb == '腹泻') {
      //       datas3[3]['腹泻'][4] = item.num;
      //     }
      //     if (item.time == '2023-03-20' && item.crb == '发烧') {
      //       datas3[4]['发烧'][0] = item.num;
      //     }
      //     if (item.time == '2023-03-21' && item.crb == '发烧') {
      //       datas3[4]['发烧'][1] = item.num;
      //     }
      //     if (item.time == '2023-03-22' && item.crb == '发烧') {
      //       datas3[4]['发烧'][2] = item.num;
      //     }
      //     if (item.time == '2023-03-23' && item.crb == '发烧') {
      //       datas3[4]['发烧'][3] = item.num;
      //     }
      //     if (item.time == '2023-03-24' && item.crb == '发烧') {
      //       datas3[4]['发烧'][4] = item.num;
      //     }
      //   }
      // });
      // this.bzqs = datas3;
      // console.log(datas3);
      //获取天气
      //this.getWeather();

      //this.loading = false;
    },
    //获取or刷新token
    async getToken() {
      const { data } = await getToken({
        appId: 'S20232002',
        appSecret: '300cc0a242577bb0d78bcabf339ca08d',
        timestamp: new Date().getTime(),
      });
      this.token = data;
    },
    //获取班级
    async getClass() {
      //获取班级
      const { data: bj } = await schoolClassTabulationData({
        ...this.config,
        gradeCode: this.query.gradeCode,
      });
      this.bj = eval(bj);
    },
    //获取时间
    getTimeState() {
      // 获取当前时间
      let timeNow = new Date();
      // 获取当前小时
      let hours = timeNow.getHours();
      // 判断当前时间段
      if (hours >= 7 && hours < 11) {
        this.chenWuState = 1;
      } else if (hours >= 11 && hours < 16) {
        this.chenWuState = 2;
      } else {
        this.chenWuState = 3;
      }
    },
    getWeather() {
      // 第三方天气api接口
      axios
        .get('https://v0.yiketianqi.com/api', {
          params: {
            unescape: '1',
            appid: '51385758',
            appsecret: '1PDw7ktP ',
            version: 'v61',
            city: '成都',
          },
        })
        .then((res) => {
          // console.log(res);
          if (res) {
            this.weatcherData = res;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //传染病分布
    initCrbfb() {
      let chartColumn = echarts.init(this.$refs.crbfb);
      let option = {
        color: ['#fdb55b'],
        radar: [
          {
            indicator: this.rcrb.map((item) => {
              return {
                text: item.illnessName,
                max: Math.max.apply(
                  Math,
                  this.rcrb.map(function (o) {
                    return o.illnessNumber * 1.1;
                  })
                ),
              };
            }),
            name: {
              textStyle: {
                fontSize: 44, //外圈标签字体大小
                color: '#5b81cb', //外圈标签字体颜色
              },
            },
            center: ['50%', '53%'],
            radius: 130,
            axisName: {
              color: '#09246B',
              borderRadius: 3,
              padding: [3, 5],
              fontSize: '3rem',
            },
          },
        ],
        series: [
          {
            type: 'radar',
            radarIndex: 0,
            data: [
              {
                value: this.rcrb.map((item) => {
                  return item.illnessNumber;
                }),
                areaStyle: {
                  color: new echarts.graphic.RadialGradient(0.1, 0.7, 0.9, [
                    {
                      color: 'rgba(250,210,55,0.1)',
                      offset: 0,
                    },
                    {
                      color: 'rgba(254,154,30, 0.9)',
                      offset: 1,
                    },
                  ]),
                },
              },
            ],
          },
        ],
      };
      chartColumn.setOption(option);
    },
    //近一周病症趋势
    initJyzbzqs() {
      let chartColumn = echarts.init(this.$refs.jyzbzqs);
      let option = {
        color: ['#FAD237', '#10B27B', '#7CB8FF', '#3870FF', '#FEAF5B'],
        tooltip: {
          trigger: 'axis',
          confine: true,
          textStyle: {
            fontSize: '24',
          },
        },
        legend: {
          data: this.jyzbzqs[0].nums,
          left: 'right',
          top: '5%',
          icon: 'rect',
          textStyle: {
            fontSize: '2rem',
            color: '#09246B',
          },
          itemHeight: 4,
          itemWidth: 12,
        },
        grid: {
          left: '12%',
          top: '20%',
          right: '10%',
          bottom: '19%',
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: true,
            data: this.jyzbzqs.map((item) => {
              return (
                item.check_date.split('-')[1] +
                '/' +
                item.check_date.split('-')[2]
              );
            }),
            axisLabel: {
              interval: 0,
              textStyle: {
                fontSize: '2.6rem',
                color: '#09246B',
              },
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            axisLine: {
              show: true,
            },
            axisLabel: {
              textStyle: {
                fontSize: '3rem',
                color: '#09246B',
              },
            },
          },
        ],
        series: [
          {
            name: '咳嗽',
            type: 'line',
            smooth: true,
            showSymbol: false,
            areaStyle: {
              opacity: 0.5,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#FAD237',
                },
                {
                  offset: 1,
                  color: '#FAD237',
                },
              ]),
            },
            emphasis: {
              focus: 'series',
            },
            data: this.jyzbzqs.map((item) => {
              return item.subList[0];
            }),
          },
          {
            name: '发烧',
            type: 'line',
            smooth: true,
            showSymbol: false,
            areaStyle: {
              opacity: 0.5,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#10B27B',
                },
                {
                  offset: 1,
                  color: '#10B27B',
                },
              ]),
            },
            emphasis: {
              focus: 'series',
            },
            data: this.jyzbzqs.map((item) => {
              return item.subList[1];
            }),
          },
          {
            name: '腹泻',
            type: 'line',
            smooth: true,
            showSymbol: false,
            areaStyle: {
              opacity: 0.5,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#7CB8FF',
                },
                {
                  offset: 1,
                  color: '#7CB8FF',
                },
              ]),
            },
            emphasis: {
              focus: 'series',
            },
            data: this.jyzbzqs.map((item) => {
              return item.subList[2];
            }),
          },
          {
            name: '呕吐',
            type: 'line',
            smooth: true,
            showSymbol: false,
            areaStyle: {
              opacity: 0.5,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#7CB8FF',
                },
                {
                  offset: 1,
                  color: '#7CB8FF',
                },
              ]),
            },
            emphasis: {
              focus: 'series',
            },
            data: this.jyzbzqs.map((item) => {
              return item.subList[3];
            }),
          },
          {
            name: '流鼻涕',
            type: 'line',
            smooth: true,
            showSymbol: false,
            areaStyle: {
              opacity: 0.5,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#fff8df',
                },
                {
                  offset: 1,
                  color: '#fff8df',
                },
              ]),
            },
            emphasis: {
              focus: 'series',
            },
            data: this.jyzbzqs.map((item) => {
              return item.subList[4];
            }),
          },
          {
            name: '头晕',
            type: 'line',
            smooth: true,
            showSymbol: false,
            areaStyle: {
              opacity: 0.5,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#cd4d17',
                },
                {
                  offset: 1,
                  color: '#cd4d17',
                },
              ]),
            },
            emphasis: {
              focus: 'series',
            },
            data: this.jyzbzqs.map((item) => {
              return item.subList[5];
            }),
          },
          {
            name: '咽痛',
            type: 'line',
            smooth: true,
            showSymbol: false,
            areaStyle: {
              opacity: 0.5,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#7fa2ff',
                },
                {
                  offset: 1,
                  color: '#7fa2ff',
                },
              ]),
            },
            emphasis: {
              focus: 'series',
            },
            data: this.jyzbzqs.map((item) => {
              return item.subList[6];
            }),
          },
          {
            name: '四肢无力',
            type: 'line',
            smooth: true,
            showSymbol: false,
            areaStyle: {
              opacity: 0.5,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#0eb17a',
                },
                {
                  offset: 1,
                  color: '#0eb17a',
                },
              ]),
            },
            emphasis: {
              focus: 'series',
            },
            data: this.jyzbzqs.map((item) => {
              return item.subList[7];
            }),
          },
        ],
      };
      chartColumn.setOption(option);
    },
    //晨午检完成趋势
    initCwjwcqsChart() {
      if (this.cwjwcqs.length > 0) {
        let chartColumn = echarts.init(this.$refs.cwjwcqs);
        let option = {
          tooltip: {
            trigger: 'axis',
            confine: true,
            textStyle: {
              fontSize: '24',
            },
          },
          legend: {
            data: this.cwjwcqs[0].subList,
            left: 'right',
            top: '5%',
            icon: 'rect',
            itemHeight: 4,
            itemWidth: 12,
            textStyle: {
              fontSize: '2.6rem',
            },
          },
          grid: {
            left: '8%',
            top: '17%',
            right: '6%',
            bottom: '19%',
            // containLabel: true,
          },
          xAxis: {
            axisLabel: {
              textStyle: {
                fontSize: '3rem',
                color: '#09246B',
              },
            },
            type: 'category',
            boundaryGap: true,
            data: this.cwjwcqs.map((item) => {
              return (
                item.check_date.split('-')[1] +
                '/' +
                item.check_date.split('-')[2]
              );
            }),
          },
          yAxis: {
            axisLabel: {
              textStyle: {
                fontSize: '3rem',
                color: '#09246B',
              },
            },
            type: 'value',
            axisLine: {
              show: true,
            },
          },
          color: ['#10B27B', '#3870FF', '#FEAF5B'],
          series: [
            {
              name: this.cwjwcqs[0].subList[0],
              type: 'line',
              areaStyle: {
                normal: {
                  // 渐变填充色（线条下半部分）
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: '#10B27B',
                    },
                    {
                      offset: 1,
                      color: '#10B27B00',
                    },
                  ]),
                },
              },
              symbol: 'none',
              emphasis: {
                focus: 'series',
              },
              data: this.cwjwcqs.map((item) => {
                return item.nums[0];
              }),
            },
            {
              name: this.cwjwcqs[0].subList[1],
              type: 'line',
              areaStyle: {
                normal: {
                  // 渐变填充色（线条下半部分）
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: '#3870FF',
                    },
                    {
                      offset: 1,
                      color: '#3870FF00',
                    },
                  ]),
                },
              },
              symbol: 'none',
              emphasis: {
                focus: 'series',
              },
              data: this.cwjwcqs.map((item) => {
                return item.nums[1];
              }),
            },
            {
              name: this.cwjwcqs[0].subList[2],
              type: 'line',
              areaStyle: {
                normal: {
                  // 渐变填充色（线条下半部分）
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: '#FEAF5B',
                    },
                    {
                      offset: 1,
                      color: '#FEAF5B00',
                    },
                  ]),
                },
              },
              symbol: 'none',
              emphasis: {
                focus: 'series',
              },
              data: this.cwjwcqs.map((item) => {
                return item.nums[2];
              }),
            },
          ],
        };
        chartColumn.setOption(option);
      }
    },
    //出勤情况趋势
    initCqqkqs() {
      let chartColumn = echarts.init(this.$refs.cqqkqs);
      let option = {
        tooltip: {
          show: true,
          confine: true,
          textStyle: {
            fontSize: '24',
          },
        },
        legend: {
          data: ['应到人数', '实到人数'],
          left: 'right',
          top: '5%',
          // icon: 'rect',
          // itemHeight: 4,
          // itemWidth: 12,
          textStyle: {
            fontSize: '2.6rem',
          },
        },
        grid: {
          left: '8%',
          top: '17%',
          right: '6%',
          bottom: '19%',
          // containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            data: this.cqqkqs.map((item) => {
              return (
                item.check_date.split('-')[1] +
                '/' +
                item.check_date.split('-')[2]
              );
            }),
            axisLabel: {
              textStyle: {
                fontSize: '3rem',
                color: '#09246B',
              },
            },
            axisPointer: {
              type: 'shadow',
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            min: 0,
            //max: Math.max(...this.cqqs.shidao) * 1.1,
            axisLabel: {
              textStyle: {
                fontSize: '3rem',
                color: '#09246B',
              },
            },
            axisLine: {
              show: true,
            },
          },
          {
            type: 'value',
            name: '实到人数',
            show: false,
            min: 0,
            //max: Math.max(...this.cqqs.shidao) * 1.1,
          },
        ],
        series: [
          {
            name: '应到人数',
            type: 'bar',
            barWidth: '40%',
            itemStyle: {
              barBorderRadius: [7, 7, 0, 0],
              color: '#3870FF',
            },
            tooltip: {
              valueFormatter: function (value) {
                return value + ' 人';
              },
            },
            data: this.cqqkqs.map((item) => {
              return item.nums.reduce(function (total, value) {
                return total + value;
              }, 0);
            }),
          },
          {
            name: '实到人数',
            type: 'line',
            smooth: true,
            itemStyle: {
              normal: {
                color: '#FEAF5B',
              },
            },
            tooltip: {
              valueFormatter: function (value) {
                return value + ' 人';
              },
            },
            data: this.cqqkqs.map((item) => {
              return item.subList.reduce(function (total, value) {
                return total + value;
              }, 0);
            }),
          },
        ],
      };
      chartColumn.setOption(option);
    },
    //近一周传染病趋势
    initJyzcrbqs() {
      let chartColumn = echarts.init(this.$refs.jyzcrbqs);
      let option = {
        tooltip: {
          trigger: 'axis',
          confine: true,
          textStyle: {
            fontSize: '24',
          },
        },
        legend: {
          data: this.jyzcrbqs[0].nums,
          left: 'right',
          top: '5%',
          icon: 'rect',
          textStyle: {
            fontSize: '2rem',
            color: '#09246B',
          },
          itemHeight: 4,
          itemWidth: 12,
        },
        grid: {
          left: '8%',
          top: '20%',
          right: '10%',
          bottom: '19%',
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.jyzcrbqs.map((item) => {
            return (
              item.check_date.split('-')[1] +
              '/' +
              item.check_date.split('-')[2]
            );
          }),
          axisLabel: {
            textStyle: {
              fontSize: '2.6rem',
              color: '#09246B',
            },
          },
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: true,
          },
          axisLabel: {
            textStyle: {
              fontSize: '3rem',
              color: '#09246B',
            },
          },
        },
        series: [
          {
            name: '甲流',
            smooth: true,
            symbolSize: 0,
            type: 'line',
            data: this.jyzcrbqs.map((item) => {
              return item.subList[0];
            }),
            itemStyle: {
              normal: {
                color: '#FAD237',
              },
            },
          },
          {
            name: '新冠病毒感染',
            type: 'line',
            smooth: true,
            symbolSize: 0,
            itemStyle: {
              normal: {
                color: '#10B27B',
              },
            },
            data: this.jyzcrbqs.map((item) => {
              return item.subList[1];
            }),
          },
          {
            name: '水痘',
            smooth: true,
            symbolSize: 0,
            type: 'line',
            data: this.jyzcrbqs.map((item) => {
              return item.subList[2];
            }),
            itemStyle: {
              normal: {
                color: '#7CB8FF',
              },
            },
          },
          {
            name: '流行性感冒',
            smooth: true,
            symbolSize: 0,
            type: 'line',
            data: this.jyzcrbqs.map((item) => {
              return item.subList[3];
            }),
            itemStyle: {
              normal: {
                color: '#3870FF',
              },
            },
          },
          {
            name: '流行性腮腺炎',
            smooth: true,
            symbolSize: 0,
            type: 'line',
            data: this.jyzcrbqs.map((item) => {
              return item.subList[4];
            }),
            itemStyle: {
              normal: {
                color: '#FEAF5B',
              },
            },
          },
        ],
      };
      chartColumn.setOption(option);
    },
    //近一周病情趋势
    initJyzbqqs() {
      let chartColumn = echarts.init(this.$refs.jyzbqqs);
      let option = {
        tooltip: {
          trigger: 'axis',
          confine: true,
          textStyle: {
            fontSize: '24',
          },
        },
        legend: {
          data: this.jyzbqqs[0].nums,
          left: 'right',
          top: '5%',
          icon: 'rect',
          textStyle: {
            fontSize: '2rem',
            color: '#09246B',
          },
          itemHeight: 4,
          itemWidth: 12,
        },
        grid: {
          left: '8%',
          top: '20%',
          right: '10%',
          bottom: '19%',
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.jyzbqqs.map((item) => {
            return (
              item.check_date.split('-')[1] +
              '/' +
              item.check_date.split('-')[2]
            );
          }),
          axisLabel: {
            textStyle: {
              fontSize: '2.6rem',
              color: '#09246B',
            },
          },
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: true,
          },
          axisLabel: {
            textStyle: {
              fontSize: '3rem',
              color: '#09246B',
            },
          },
        },
        series: [
          {
            name: '普通感冒',
            type: 'line',
            data: this.jyzbqqs.map((item) => {
              return item.subList[0];
            }),
            itemStyle: {
              normal: {
                color: '#7CB8FF',
                lineStyle: {
                  color: '#7CB8FF',
                },
              },
            },
          },
          {
            name: '肠胃炎',
            type: 'line',
            itemStyle: {
              normal: {
                color: '#3F80FF',
                lineStyle: {
                  color: '#3F80FF',
                },
              },
            },
            data: this.jyzbqqs.map((item) => {
              return item.subList[1];
            }),
          },
          {
            name: '支气管炎',
            type: 'line',
            data: this.jyzbqqs.map((item) => {
              return item.subList[2];
            }),
            itemStyle: {
              normal: {
                color: '#FEAF5B',
                lineStyle: {
                  color: '#FEAF5B',
                },
              },
            },
          },
          {
            name: '耳鼻喉疾病',
            type: 'line',
            data: this.jyzbqqs.map((item) => {
              return item.subList[3];
            }),
            itemStyle: {
              normal: {
                color: '#10B27B',
                lineStyle: {
                  color: '#10B27B',
                },
              },
            },
          },
          {
            name: '过敏性皮炎',
            type: 'line',
            data: this.jyzbqqs.map((item) => {
              return item.subList[4];
            }),
            itemStyle: {
              normal: {
                color: '#09246b',
                lineStyle: {
                  color: '#09246b',
                },
              },
            },
          },
          {
            name: '意外伤害',
            type: 'line',
            data: this.jyzbqqs.map((item) => {
              return item.subList[5];
            }),
            itemStyle: {
              normal: {
                color: '#fff39d',
                lineStyle: {
                  color: '#fff39d',
                },
              },
            },
          },
        ],
      };
      chartColumn.setOption(option);
    },
    initJyzrbqk() {
      let chartColumn = echarts.init(this.$refs.jyzrbqk);
      let option = {
        tooltip: {
          trigger: 'axis',
          confine: true,
          textStyle: {
            fontSize: '24',
          },
          axisPointer: {
            type: 'none',
          },
        },
        grid: {
          left: '26%',
          top: '8%',
          right: '17%',
          bottom: '3%',
        },
        xAxis: {
          show: false,
          type: 'value',
        },
        yAxis: {
          type: 'category',
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            margin: 76,
            textStyle: {
              align: 'left',
              color: '#09246B',
            },
          },
          data: this.lnfect.map((item) => {
            return item.illness_name;
          }),
        },
        series: [
          {
            name: '近一周传染病',
            type: 'bar',
            label: {
              normal: {
                show: true,
                formatter: '{c}人',
                position: 'right',
                textStyle: {
                  color: 'auto',
                  fontSize: 14,
                },
              },
            },
            barWidth: 12,
            data: this.lnfect.map((item) => {
              return item.nums;
            }),
            itemStyle: {
              normal: {
                //这里设置每个柱子颜色不一样
                color: function (params) {
                  // 定义一个颜色数组colorList
                  var colorList = [
                    '#FE9A1E',
                    '#3870FF',
                    '#0EB17A',
                    '#c8ba23',
                    '#BC6BF7',
                    '#0EB17A',
                    '#FE6463',
                    '#09246B',
                    '#7CB8FF',
                  ];
                  return colorList[params.dataIndex];
                },
              },
              color: '#3971ff',
              barBorderRadius: [0, 4, 4, 0],
            },
          },
        ],
      };
      chartColumn.setOption(option);
      // chartColumn.on('click', (param) => {
      //   this.dialogVisible = true;
      //   this.dialogType = 4;
      //   this.dialogParam.title = '近一周' + param.name + '统计';
      //   this.$refs['dialog'].getIllNums(param.name);
      // });
    },
    initJyzptb() {
      let chartColumn = echarts.init(this.$refs.jyzptb);
      let option = {
        tooltip: {
          trigger: 'axis',
          textStyle: {
            fontSize: '24',
          },
          axisPointer: {
            type: 'none',
          },
          formatter: function (params) {
            return params[0].name + ': ' + params[0].value;
          },
        },
        grid: {
          left: '12%',
          top: '17%',
          right: '6%',
          bottom: '19%',
        },
        xAxis: {
          data: this.rcgb.map((item) => {
            return item.illnessName;
          }),
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            fontSize: '2rem',
            color: '#09246B',
            interval: 0,
            formatter: function (value) {
              var texts = value;
              if (texts.length > 2) {
                // 限制长度自设
                texts =
                  texts.substr(0, 2) +
                  '\n' +
                  texts.substr(2, 2) +
                  '\n' +
                  texts.substr(4, 2);
              }
              return texts;
            },
          },
        },
        yAxis: {
          axisLabel: {
            textStyle: {
              fontSize: '3rem',
              color: '#09246B',
            },
          },
          axisLine: {
            show: true,
          },
        },
        color: ['#e54035'],
        series: [
          {
            name: '普通病排名',
            type: 'pictorialBar',
            barWidth: '80%',
            barCategoryGap: '-60%',
            // symbol: 'path://M0,10 L10,10 L5,0 L0,10 z',
            symbol:
              'path://M0,10 L10,10 C5.5,10 5.5,5 5,0 C4.5,5 4.5,10 0,10 z',
            itemStyle: {
              normal: {
                //渐变色
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#3870FF',
                  },
                  {
                    offset: 1,
                    color: '#bfd0ff',
                  },
                ]),
              },
            },
            emphasis: {
              itemStyle: {
                opacity: 1,
              },
            },
            data: this.rcgb.map((item) => {
              return item.illnessNumber;
            }),
            z: 10,
          },
          {
            name: '普通病排名',
            type: 'pictorialBar',
            barGap: '-100%',
            symbolPosition: 'end',
            symbolSize: 50,
          },
        ],
      };
      chartColumn.setOption(option);
      // chartColumn.on('click', (param) => {
      //   this.dialogVisible = true;
      //   this.dialogType = 4;
      //   this.dialogParam.title = '近一周' + param.name + '统计';
      //   this.$refs['dialog'].getSymNums(param.name);
      // });
    },
    //应检班级
    initJbqk() {
      let chartColumn = echarts.init(this.$refs.jbqk);
      let option = {
        tooltip: {
          trigger: 'item',
          confine: true,
          textStyle: {
            fontSize: '16',
          },
          axisPointer: {
            type: 'none',
          },
        },
        legend: {
          itemHeight: 15,
          itemWidth: 15,
          itemGap: 16,
          orient: 'vertical', //垂直显示
          y: 'center', //延Y轴居中
          x: '55%', //居右显示
          textStyle: {
            fontSize: '2.6rem',
            color: '#09246B',
          },
        },
        color: ['#5696ff', '#10b27b', '#fe9a1e'], // 自定义颜色范围
        series: [
          {
            name: '应检班级',
            type: 'pie',
            center: ['35%', '50%'],
            radius: ['35%', '80%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center',
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '2.4rem',
                fontWeight: 'bold',
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              {
                value: this.djbj[0].nums,
                name: '晨检人数',
              },
              {
                value: this.djbj[1].nums,
                name: '午检人数',
              },
              {
                value: this.djbj[2].nums,
                name: '晚检人数',
              },
            ],
          },
        ],
      };
      chartColumn.setOption(option);
    },
    //已检班级
    initRcwj() {
      let chartColumn = echarts.init(this.$refs.rcwj);
      let option = {
        tooltip: {
          trigger: 'item',
          confine: true,
          textStyle: {
            fontSize: '20',
          },
          axisPointer: {
            type: 'none',
          },
        },
        legend: {
          itemHeight: 15,
          itemWidth: 15,
          itemGap: 16,
          orient: 'vertical', //垂直显示
          y: 'center', //延Y轴居中
          x: '55%', //居右显示
          textStyle: {
            fontSize: '2.6rem',
            color: '#09246B',
          },
        },
        color: ['#5696FF', '#10B27B', '#FE9A1E'],
        series: [
          {
            name: '已检班级',
            type: 'pie',
            roseType: 'area',
            center: ['35%', '50%'],
            radius: ['15%', '80%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center',
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '2.4rem',
                fontWeight: 'bold',
              },
            },
            labelLine: {
              show: false,
            },
            itemStyle: {
              borderRadius: 8,
            },
            data: [
              {
                value: this.yjbj[0].nums,
                name: '晨检人数',
              },
              {
                value: this.yjbj[1].nums,
                name: '午检人数',
              },
              {
                value: this.yjbj[2].nums,
                name: '晚检人数',
              },
            ],
          },
        ],
      };
      chartColumn.setOption(option);
    },
    //日请假
    initRqj() {
      let chartColumn = echarts.init(this.$refs.rqj);
      let option = {
        tooltip: {
          trigger: 'item',
          confine: true,
          textStyle: {
            fontSize: '24',
          },
          axisPointer: {
            type: 'none',
          },
        },
        legend: {
          itemHeight: 15,
          itemWidth: 15,
          itemGap: 18,
          orient: 'vertical', //垂直显示
          y: 'center', //延Y轴居中
          x: '55%', //居右显示
          textStyle: {
            fontSize: '2.6rem',
          },
        },
        graphic: [
          {
            //环形图中间添加文字
            type: 'text', //通过不同top值可以设置上下显示
            left: '32%',
            top: '38%',
            style: {
              text: this.rqj.personal_leave_nu + this.rqj.sick_leave_num,
              textAlign: 'center',
              fill: '#09246B', //文字的颜色
              fontSize: '3rem',
              fontFamily: 'YouSheBiaoTiHei',
            },
          },
          // 写了两个文字插入是因为业务要求两行不一样样式的字
          {
            //环形图中间添加文字
            type: 'text', //通过不同top值可以设置上下显示
            left: '27%',
            top: '55%',
            style: {
              text: '请假总人数',
              textAlign: 'center',
              fill: '#1C4AC2', //文字的颜色
              fontSize: '2.2rem',
              fontFamily: 'PingFangSC', // 这个自己看着办
            },
          },
        ],
        color: ['#5696FF', '#10B27B'],
        series: [
          {
            name: '日请假',
            type: 'pie',
            center: ['35%', '50%'],
            radius: ['65%', '85%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center',
            },
            emphasis: {
              label: {
                show: false,
                fontSize: '2.4rem',
                fontWeight: 'bold',
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              {
                value: this.rqj.personal_leave_nu,
                name: '事假人数',
              },
              {
                value: this.rqj.sick_leave_num,
                name: '病假人数',
              },
            ],
          },
        ],
      };
      chartColumn.setOption(option);
    },
    initRzzpm() {
      let chartColumn = echarts.init(this.$refs.rzzpm);
      const COLOR_ARRAY = {
        chart_3: {
          color_1: ['#0EB17A', '#3870FF', '#FE9A1E'],
        },
      };
      let dataList3 = {
        list: this.rzz.map((item) => {
          return { name: item.illnessName, value: item.illnessNumber };
        }),
      };
      if (dataList3.list.length == 0) {
        return false;
      }
      let maxValue = dataList3.list[0].value;
      const grayBar = dataList3.list.map((item) => {
        return item.value * 1;
      });
      const xList = dataList3.list.map((item) => {
        return item.name;
      });
      const vStyleList = dataList3.list.map((item, index) => {
        let i = index;
        if (index > 1) {
          i = 2;
        }
        let color =
          item.value < 10
            ? COLOR_ARRAY.chart_3.color_1[0]
            : item.value < 100
            ? COLOR_ARRAY.chart_3.color_1[1]
            : COLOR_ARRAY.chart_3.color_1[2];
        let itemStyle = {
          color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
            {
              offset: 1,
              color: color,
            },
          ]),
        };
        const data = {
          value: item.value,
          itemStyle,
        };
        return data;
      });
      const vList = dataList3.list.map((item) => {
        return item.value;
      });
      let option = {
        grid: {
          left: '32%',
          right: '18%',
          bottom: '2%',
          top: '2%',
        },
        xAxis: [
          {
            show: false,
          },
        ],
        yAxis: {
          type: 'category',
          inverse: true,
          axisLabel: {
            show: false, //让Y轴数据不显示
          },
          axisTick: {
            show: false, //隐藏Y轴刻度
          },
          axisLine: {
            show: false, //隐藏Y轴线段
          },
        },
        series: [
          //背景色
          {
            show: true,
            type: 'bar',
            barWidth: '40%',
            itemStyle: {
              color: 'rgba(13, 118, 93, 0)', //填充色
            },
            label: {
              show: true,
              color: '#09246B',
              fontSize: '3rem',
              position: 'right',
              formatter: function (data) {
                return '\t' + vList[data.dataIndex] + '人';
              },
            },
            z: 1,
            data: grayBar,
          },
          //蓝条
          {
            show: true,
            type: 'bar',
            barGap: '-110%',
            barWidth: '40%',
            z: -12,
            max: 1,
            label: {
              show: true,
              // textStyle: {
              color: '#09246B', //百分比颜色
              fontSize: '3rem',
              // },
              position: 'left',
              formatter: function (data) {
                //富文本固定格式{colorName|这里填你想要写的内容}
                return xList[data.dataIndex] + '\t';
              },
            },
            data: vStyleList,
          },
          {
            name: 'dotted',
            type: 'pictorialBar',
            symbol: 'rect',
            itemStyle: {
              color: '#fff',
            },
            symbolRepeat: true,
            symbolSize: [2, 50],
            symbolMargin: 5,
            data: vList,
          },
        ],
      };
      chartColumn.setOption(option);
      // chartColumn.on('click', (param) => {
      //   this.dialogVisible = true;
      //   this.dialogType = 5;
      //   this.dialogParam.title = '近一周' + param.name + '症状统计';
      //   this.$refs['dialog'].getSymNums(param.name);
      // });
    },
    //点击基础信息
    // baseInfoClick() {
    //   this.dialogVisible = true;
    //   this.dialogType = 1;
    //   this.dialogParam.title = '基本情况';
    //   this.$refs['dialog'].getBase();
    // },
    //点击学生信息
    getStudentInfo(row) {
      this.dialogVisible = true;
      this.dialogType = 7;
      this.dialogParam.width = '120rem';
      this.dialogParam.title = row['学生'] + row['检查类型'] + '检情况';
      this.$refs['dialog'].getStudentInfo(row['学生ID'], row['检查类型']);
    },
    //当日晨午检
    chenwuClick() {
      this.dialogVisible = true;
      this.dialogType = 2;
      this.dialogParam.title = '3月24日晨午检';
      this.dialogParam.width = '55%';
      this.$refs['dialog'].getcheckNums();
    },
    //请假
    leaveNumsClick() {
      this.dialogVisible = true;
      this.dialogType = 3;
      this.dialogParam.title = '3月24日请假';
      this.dialogParam.width = '55%';
      this.$refs['dialog'].getleaveNums();
    },
    //获取学校人员信息
    async getIllNums() {
      //晨午检情况
      const { data: cwjqk } = await schoolStuLeaveInfoData({
        ...this.config,
        ...this.getFirstDayAndLastDay(),
        ...this.query,
      });
      this.cwjqk = eval(cwjqk);
    },
  },
};
</script>
<style>
html {
  font-size: 10px;
}

/* @media screen and (width: 5760px) {
    html{
      font-size: 5px;
    }
  }
  @media screen and (width: 8640px) {
    
  } */
</style>
<style scoped lang="scss">
@font-face {
  font-family: Rajdhani;
  src: url('../../assets/fonts/站酷仓耳渔阳体-W05.ttf');
}

@font-face {
  font-family: DS-Digital;
  src: url('../../assets/fonts/DS-DIGIB-2.ttf');
}

@font-face {
  font-family: PingFangSC;
  src: url('../../assets/fonts/苹方黑体-中粗-简.ttf');
}

@font-face {
  font-family: YouSheBiaoTiHei;
  src: url('../../assets/fonts/YouSheBiaoTiHei-2.ttf');
}

@mixin scrollBarStyle() {
  &::-webkit-scrollbar {
    width: 0.4rem;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2rem;
    -webkit-box-shadow: inset 0 0 0.5rem #3870ff;
    background: #3870ff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2rem;
    -webkit-box-shadow: inset 0 0 0.5rem #3870ff;
    background: #3870ff;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.5rem rgba(210, 222, 255, 0.6);
    border-radius: 0;
    background: rgba(210, 222, 255, 0.6);
  }
}

.operation {
  font-size: 2.8rem;
  display: flex;
  font-family: PingFangSC;
  justify-content: space-between;
  padding: 1.5rem 0;
  color: #09246b;

  .info {
    border: 1px #000 solid;
  }

  > div {
    align-items: center;
    display: flex;
  }

  .title {
    width: 7rem;
  }
}

.table {
  padding: 0 2rem;

  ::v-deep colgroup {
    width: 0 !important;
  }

  ::v-deep.el-table {
    color: #1c4ac2;
    font-size: 2.8rem;
    font-family: PingFangSC !important;
    display: flex;
    flex-direction: column;
  }

  ::v-deep thead {
    .cell {
      text-align: center;
      line-height: 3rem;
    }
  }

  ::v-deep tbody {
    background: #fff;
    border: 1px #000 solid;
    flex: 1;

    .cell {
      text-align: center;
      line-height: 3rem;
    }

    .el-table__row {
      height: 6.3rem;
      cursor: pointer;

      td:first-child {
        .cell {
          text-align: left;
          color: #09246b;
        }
      }
    }
  }

  ::v-deep.el-table thead {
    color: #09246b;
  }

  ::v-deep.el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border: none;
  }

  ::v-deep.el-table tr {
    background: transparent;
  }

  ::v-deep .el-table,
  .el-table__expanded-cell {
    background-color: transparent;
  }

  ::v-deep .el-table tr {
    background-color: transparent !important;
  }

  ::v-deep .el-table--enable-row-transition .el-table__body td,
  .el-table .cell {
    background-color: transparent;
  }

  ::v-deep.el-table::before {
    //去除底部白线
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0px;
  }
}

.font-class-weight {
  font-weight: bold;
  color: #09246b;
}

.box {
  // border: 1px #000 solid;
  // width: 1920px;
  // height: 360px;
  .container {
    // width: 5760px;
    // height: 1080px;
    //height: 100vh;
    // width: 8640px;
    // height: 1620px;
    //   width: 100%;
    // height: 100vh;
    // width: 100vw;
    //min-width: 570rem;
    // width: 100vw;
    //8640*1620
    background-color: #e2ecfd;
    display: flex;
    flex-direction: column;
    .header {
      height: 9.4rem;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .now-time {
        font-family: DS-Digital;
        font-size: 5.6rem;
      }

      .time,
      .weather {
        color: #fff;
        font-family: PingFangSC;
        font-size: 4rem;
        width: 80rem;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 7.5rem;
        }
      }

      .title {
        font-family: Rajdhani;
        font-size: 5.5rem;
        display: flex;
        align-items: center;
        line-height: 100%;
        text-align: center;
        color: #fff;
        letter-spacing: 0.3rem;

        img {
          height: 7.6rem;
          margin-right: 4rem;
        }
      }

      background: url('../../assets/statisitics/Group 1460@2x.png');
      background-size: 100% 100%;
    }

    .content {
      width: 100%;
      flex: 1;
      background-image: url('../../assets/image/shading.png');
      background-size: 100% 100%;
      // background: url('../../assets/statisitics/Group 1459@2x.png');
      // background-size: 100% 100%;
      display: flex;
      flex-direction: row;
      padding: 1.5rem;
      position: relative;

      .wcj {
        height: 100%;
        flex: 2 !important;

        .item-box {
          background: transparent !important;
          backdrop-filter: unset !important;
        }

        // width: 100%;
        // overflow: hidden;
      }

      .l-b {
        // border: 1px #000 solid;
        position: absolute;
        left: 0;
        width: 37.3%;
        bottom: 0;
        height: 50%;
        display: flex;
        padding: 1.5rem 0 3rem 3rem;
        justify-content: space-between;

        .item-box {
          border-radius: 2rem;
          // border: 0.2rem solid #000;
          box-shadow: 0px 0px 20px 0px rgba(41, 91, 173, 0.1);
          backdrop-filter: blur(1rem);
          overflow: hidden;
          background: #ffffff;
          width: 49.3%;

          .echarts {
            height: calc(100% - 7rem);
          }
        }
      }

      .col-box {
        flex: 1;
        display: flex;
        flex-direction: column;
        width: 0px;

        .item-box {
          border-radius: 2rem;
          // border: 0.2rem solid #000;
          box-shadow: 0px 0px 20px 0px rgba(41, 91, 173, 0.1);
          backdrop-filter: blur(1rem);
          // padding: 0.5rem;
          // height: 100%;
          overflow: hidden;
          margin: 1.5rem;
          background: #ffffff;
          // background: linear-gradient(180deg, #f1f5ff, #ffffff 100%);
          flex: 1;
          height: 0;

          // margin: 2rem;
          .center {
            display: flex;
            flex-direction: column;
            height: 100%;

            .top {
              .totals {
                position: relative;

                .total {
                  text-align: center;

                  .icon {
                    margin: 0 auto;
                  }

                  .title {
                    color: #09246b;
                    font-size: 2.2rem;
                    font-family: PingFangSC;
                    margin-top: 1rem;
                  }

                  .num {
                    color: #09246b;
                    font-size: 4.8rem;
                    font-family: YouSheBiaoTiHei;
                  }

                  height: 10rem;
                  position: absolute;
                  width: 100%;
                }
              }

              height: 34%;
              display: flex;
              justify-content: space-between;

              > div {
                width: 24%;
                padding-bottom: 2rem;

                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 1rem;
                  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                  object-fit: cover;
                }
              }
            }

            .bottom {
              background: #fff;
              border-radius: 2rem;
              // border: 1px #000 solid;
              flex: 1;

              // padding-top: 1rem;
              .echarts {
                height: 100%;
              }
            }
          }

          .chart-layout {
            height: calc(100% - 7rem);
            display: flex;
            padding: 3rem;
            flex-direction: column;

            .datas {
              font-family: PingFangSC;
              font-size: 3rem;
              display: flex;
              justify-content: space-between;

              > div {
                min-width: 20rem;
                display: flex;
                justify-content: center;
                border-radius: 0.4rem;
                padding: 2rem;
                background: #e8f0ff;
              }
            }

            .chart {
              flex: 1;

              .echarts {
                height: 100%;
                overflow: hidden;
              }
            }
          }

          .echarts {
            height: calc(100% - 7rem);
            width: 100%;
            overflow: hidden;
            @include scrollBarStyle;
            // .list {
            //   display: flex;
            //   justify-content: space-between;
            //   background: linear-gradient(270deg,
            //       rgba(124, 184, 255, 0) 0%,
            //       rgba(56, 112, 255, 0.1) 100%);
            //   padding: 0.5rem;
            //   font-family: PingFangSC;
            //   font-size: 1.5rem;
            //   color: #09246b;
            //   border-radius: 0.4rem;
            //   margin: 0.7rem;
            // }
          }
        }
      }
    }
  }
}
</style>
